﻿import * as UTILS from './utils';
import numeral from 'numeral';

/*
 * ==============================
 * Constants
 * ==============================
 *
 * Messages
 */
const SUCCESS_ADD_MESSAGE = 'Item has been added to cart!';
const FAIL_ADD_MESSAGE = 'Item has been failed added to cart!';
const CHOOSE_VARIANT_MESSAGE = 'Please choose a variant!';

/*
 * Element that show total number
 * of items in the cart
 *
 */
const $CART_QUANTITY_ELEMENTS = $('[data-action="bw-cart-quantity"]');

/*
 * ==============================
 * Constants .\END
 * ==============================
 *
 */

/*
 * ==============================
 * Cart
 * ==============================
 */
const $SIDECART_ITEM_CONTAINER = $('#bw-sidecart-item-container');

/*
 * ==============================
 * Cart .\END
 * ==============================
 */

/*
 * ==============================
 * Item Quantity Changes
 * ==============================
 *
 * Item quantity
 *
 */
let itemQuantity = 1;

/*
 * Elements for adding quantity.
 * Could only be button elements.
 *
 */
const $ADD_QUANTITY_ELEMENTS = $('button[data-action="bw-add-cart-item-quantity"]');

/*
 * Elements for reducing quantity.
 * Could only be button elements.
 *
 */
const $REDUCE_QUANTITY_ELEMENTS = $('button[data-action="bw-reduce-cart-item-quantity"]');

/*
 * Element for containing the quantity number.
 *
 */
const $QUANTITY_CONTAINER_ELEMENTS = $('[data-action="bw-cart-item-quantity"]');

let $CART_ITEM_PRICE = () => $('[data-identity="cart-item-price"]');

let $CART_ITEM_QTY = () => $('[data-identity="cart-item-qty"]');

let $CART_ITEM_SUBTOTAL = $(`[data-identity="cart-subtotal"]`);

const $SHIPPING_ADDRESS_SUBMIT_BUTTON = $('[data-action="submit-shipping-address"]');

const $SHIPPING_ADDRESS_RADIO = $('[input="shippingAddress"]');

/*
 * Adding quantity
 *
 */
function AddQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        itemQuantity = newQuantity;
    }
    else {
        itemQuantity++;
    }
}

/*
 * Reducing quantity
 *
 */
function ReduceQuantity(newQuantity) {
    if (newQuantity && newQuantity >= 0) {
        itemQuantity = newQuantity;
    }
    else {
        itemQuantity--;

        if (itemQuantity < 0) {
            itemQuantity = 0;
        }
    }
}

/*
 * Display the quantity number
 *
 */
function DisplayQuantity($elems) {
    $elems.each(function () {
        let $self = $(this);

        if ($self.is('input')) {
            $self.val(itemQuantity);
        }
        else {
            $self.text(itemQuantity);
        }
    });
}

if ($ADD_QUANTITY_ELEMENTS) {
    $ADD_QUANTITY_ELEMENTS.on('click', function () {
        let $self = $(this);

        // get the exact quantity
        let quantity = $self.data('quantity');

        AddQuantity(quantity);

        if ($QUANTITY_CONTAINER_ELEMENTS) {
            DisplayQuantity($QUANTITY_CONTAINER_ELEMENTS);
        }
    });
}

if ($REDUCE_QUANTITY_ELEMENTS) {
    $REDUCE_QUANTITY_ELEMENTS.on('click', function () {
        let $self = $(this);

        // get the exact quantity
        let quantity = $self.data('quantity');

        ReduceQuantity(quantity);

        if ($QUANTITY_CONTAINER_ELEMENTS) {
            DisplayQuantity($QUANTITY_CONTAINER_ELEMENTS);
        }
    });
}

/*
 * ==============================
 * Item Quantity Changes .\END
 * ==============================
 *
 */

/*
 * ==============================
 * Add To Cart
 * ==============================
 *
 */

/*
 * Url endpoint for adding item
 * to cart.
 *
 */
const ADD_TO_CART_URL = '/umbraco/surface/cart/addcart';

const ALL_VARIANTS_URL = '/umbraco/surface/product/getvariants';

let productVariants = [];

let chosenVariant = undefined;

/*
 * Add to cart elements.
 * Could only be button elements.
 *
 */
const $ADD_TO_CART_ELEMENTS = $('button[data-action="bw-add-cart"]');

/*
 * Element that contain current
 * selected variant in product
 * detail
 *
 */
const $ADD_TO_CART_VARIANT_ELEMENTS = $('[data-action="bw-cart-item-variant"]');

/*
 * Elements that contains
 * first options of variants
 *
 */
const $VARIANT_OPTION_1_ELEMENTS = $('[data-action="bw-cart-item-option1"]');

let option1 = undefined;

/*
 * Elements that contains
 * second options of variants
 *
 */
const $VARIANT_OPTION_2_ELEMENTS = $('[data-action="bw-cart-item-option2"]');

let option2 = undefined;

let currentlySelectedVariants = [];

/*
 * Elements that contains
 * third options of variants
 *
 */
const $VARIANT_OPTION_3_ELEMENTS = $('[data-action="bw-cart-item-option3"]');

let option3 = undefined;

if ($VARIANT_OPTION_1_ELEMENTS) {
    $VARIANT_OPTION_1_ELEMENTS.on('click', function (e) {
        let $self = $(this);
        let isRadio = $self.is('input[type="radio"]');

        // if the element is radio
        if (isRadio) {
            option1 = $self.val();

            $(`label.selected`).removeClass('selected');

            $VARIANT_OPTION_2_ELEMENTS.prop('checked', false);
            $VARIANT_OPTION_3_ELEMENTS.prop('checked', false);

            $(`label[for="option1-${option1}"]`).addClass('selected');
        }
        else {
            option1 = $self.data('option1');

            $VARIANT_OPTION_1_ELEMENTS.removeClass('selected');
            $VARIANT_OPTION_2_ELEMENTS.removeClass('selected');
            $VARIANT_OPTION_3_ELEMENTS.removeClass('selected');

            $self.addClass('selected');
        }

        // get all variant with the
        // chosen option 1
        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase());

        // if there is only one variants
        // which means no option 2
        if (currentlySelectedVariants.length == 1) {
            chosenVariant = currentlySelectedVariants[0].Id;

            EnableAddToCartElement();
        }
        else {
            $('[data-action="bw-cart-item-option2"]').prop('disabled', false);
            $('[data-action="bw-cart-item-option2"]').removeClass('disabled');

            ResetChosenVariant();
            DisableAddToCartElement()

            currentlySelectedVariants.forEach((v) => {
                if (v.Stock == 0 && !v.IsIgnoredStock) {
                    if (isRadio) {
                        $('#option2-' + v.Option2).prop('disabled', true);
                    }
                    else {
                        $('#option2-' + v.Option2).addClass('disabled');
                    }
                }
            });
        }
    });
}

if ($VARIANT_OPTION_2_ELEMENTS) {
    $VARIANT_OPTION_2_ELEMENTS.on('click', function (e) {
        let $self = $(this);
        let isRadio = $self.is('input[type="radio"]');

        if (isRadio) {
            if ($('[data-action="bw-cart-item-option1"]:checked').length) {

                option2 = $self.val();
            }
            else {
                e.preventDefault();
                Notify('Please choose the first option!');
            }
        }
        else {
            if ($('[data-action="bw-cart-item-option1"].selected').length) {
                option2 = $self.data('option2');

                $VARIANT_OPTION_2_ELEMENTS.removeClass('selected');
                $VARIANT_OPTION_3_ELEMENTS.removeClass('selected');

                $self.addClass('selected');
            }
            else {
                Notify('Please choose the first option!');
            }
        }

        // get all variant with the
        // chosen option 1 & 2
        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase() && v.Option2.toLowerCase() == option2.toLowerCase());

        if (currentlySelectedVariants.length == 1) {
            chosenVariant = currentlySelectedVariants[0].Id;

            EnableAddToCartElement();
        }
        else {
            $VARIANT_OPTION_3_ELEMENTS
                .prop('checked', false)
                .prop('disabled', false)
                .removeClass('disabled')
                .removeClass('selected');

            ResetChosenVariant();
            DisableAddToCartElement()

            currentlySelectedVariants.forEach((v) => {
                if (v.Stock == 0 && !v.IsIgnoredStock) {
                    if (isRadio) {
                        $('#option3-' + v.Option3).prop('disabled', true);
                    } else {
                        $('#option3-' + v.Option3).addClass('disabled');
                    }
                }
            })
        }
    });
}

if ($VARIANT_OPTION_3_ELEMENTS) {
    $VARIANT_OPTION_3_ELEMENTS.on('click', function (e) {
        let $self = $(this);
        let isRadio = $self.is('input[type="radio"]');

        if (isRadio) {
            if ($('[data-action="bw-cart-item-option2"]:checked').length) {
                option3 = $self.val();
            }
            else {
                e.preventDefault();
                Notify('Please choose the second option!');
            }
        }
        else {
            if ($('[data-action="bw-cart-item-option2"].selected').length) {
                option3 = $self.data('option3');

                $VARIANT_OPTION_3_ELEMENTS.removeClass('selected');

                $self.addClass('selected');
            }
            else {
                Notify('Please choose the second option!');
            }
        }

        // get all variant with the
        // chosen option 1 & 2
        currentlySelectedVariants = productVariants.filter(v => v.Option1.toLowerCase() == option1.toLowerCase() && v.Option2.toLowerCase() == option2.toLowerCase() && v.Option3.toLowerCase() == option3.toLowerCase());

        chosenVariant = currentlySelectedVariants[0].Id;

        EnableAddToCartElement();
    })
}

/**
 * Main function to actually adding
 * item to shopping cart
 */
function AddToCart(productId) {

    console.log(chosenVariant);
    console.log(productId);

    if (chosenVariant && productId) {
        // data to be sent
        let data = {
            variantId: chosenVariant,
            qty: itemQuantity,
            productId
        }

        let jqxhr = $.post(ADD_TO_CART_URL, data);

        jqxhr
            .always(() => {
                console.log(jqxhr);

                if (jqxhr.status == 200) {
                    Notify(SUCCESS_ADD_MESSAGE, 'success');

                    console.log(jqxhr);

                    // update the quantity
                    $CART_QUANTITY_ELEMENTS.text(jqxhr.responseJSON.quantity);

                    if ($('.bw-empty-cart').length) {
                        $SIDECART_ITEM_CONTAINER.empty();
                    }

                    if ($SIDECART_ITEM_CONTAINER.find(`[data-variant-id="${jqxhr.responseJSON.item.ID}]"`).length) {
                        $SIDECART_ITEM_CONTAINER.find(`[data-variant-id="${jqxhr.responseJSON.item.ID}]"`).first().remove();
                    }

                    $SIDECART_ITEM_CONTAINER.append(UTILS.CART_ITEM_ELEMENT_STRING(jqxhr.responseJSON.item));

                    // calculate subtotal cart
                    CalculateCart();

                    // clear selections
                    ResetChoices();

                    // redisabled the add to cart button
                    DisableAddToCartElement();
                }
                else if (jqxhr.status == 400) {
                    Notify(FAIL_ADD_MESSAGE, 'error');
                }

            });

    }
    else {
        Notify('Please choose a variant!');
    }
}

function DisableAddToCartElement() {
    if (!$ADD_TO_CART_ELEMENTS.hasClass('disabled')) {
        $ADD_TO_CART_ELEMENTS
            .addClass('disabled');
    }

}

function EnableAddToCartElement() {
    if ($ADD_TO_CART_ELEMENTS.hasClass('disabled')) {
        $ADD_TO_CART_ELEMENTS
            .removeClass('disabled');
    }
}

function ResetChosenVariant() {
    chosenVariant = undefined;
}

function ResetChoices() {
    $(`label.selected`).removeClass('selected');

    $VARIANT_OPTION_1_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    $VARIANT_OPTION_2_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);

    $VARIANT_OPTION_3_ELEMENTS
        .removeClass('selected')
        .prop('checked', false);
}

function CalculateCart() {
    let cartItemPrices = $CART_ITEM_PRICE();

    let subtotal = 0;

    if (cartItemPrices.length) {
        cartItemPrices.each(function (i) {
            let qty = $CART_ITEM_QTY().eq(i);

            subtotal += parseFloat($(this).data('price')) * parseFloat(qty.data('qty'));
        });
    }

    $CART_ITEM_SUBTOTAL.data('price', subtotal);

    $CART_ITEM_SUBTOTAL.text('Rp ' + numeral(subtotal).format('0,0'));
}

if ($ADD_TO_CART_ELEMENTS.length) {
    let productId = $ADD_TO_CART_ELEMENTS.first().data('productId');

    $.get(ALL_VARIANTS_URL + '?productId=' + productId)
        .done((res) => {
            productVariants = res;

            console.log(productVariants);
        });

    $ADD_TO_CART_ELEMENTS.on('click', function () {
        let $self = $(this);

        // if the button is disabled
        if ($self.hasClass('disabled')) {
            Notify(CHOOSE_VARIANT_MESSAGE)
        }
        else {
            // get the product id
            let productId = $self.data('productId');

            AddToCart(productId);
        }
    });
}

/*
 * ==============================
 * Add To Cart .\END
 * ==============================
 *
 */

if ($SHIPPING_ADDRESS_SUBMIT_BUTTON.length) {
    $SHIPPING_ADDRESS_SUBMIT_BUTTON.on('click', function () {
        let chosenAddress = $('input[name="shippingAddress"]');

        console.log(chosenAddress);

        $.post('/umbraco/surface/cart/addshipmentaddressajax', { addressId: chosenAddress })
            .done(res => {
                window.location.reload();
            })
            .fail(err => {
                console.error(err)
            });
    });
}

