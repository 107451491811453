﻿/*
 * ==============================
 * CONSTANTS
 * ==============================
 * 
 */
export const EMPTY_CART_ELEMENT_STRING = `<div class="bw-empty-cart">
    <p> You don't have any items in your cart.</p>
                </div>`;

export const CART_ITEM_ELEMENT_STRING = (cartItem) => {
    let stringToReturn = `<div class="bw-isla-header-cart__item" style="position: relative; overflow: hidden;" data-variant-id="${cartItem.ID}">
        <div class="bw-isla-header-cart__item--img" style="background: url(${cartItem.ImageUrl});"></div>
        <div class="bw-isla-header-cart__item--text d-flex justify-content-lg-between">
            <div class="bw-isla-cart-text">
                <p class="bw-isla-cart-text__title">${cartItem.Variant.ProductName}</p>`;

    if (cartItem.Variant.Option1 != null && cartItem.Variant.Option1 != undefined) {
        stringToReturn += `<p class="bw-isla-cart-text__size">Size: ${cartItem.Option1}</p>`
    }

    if (cartItem.Variant.Option2 != null && cartItem.Variant.Option2 != undefined) {
        stringToReturn += `<p class="bw-isla-cart-text__size">Color: ${cartItem.Option2}</p>`
    }

    if (cartItem.Variant.Option3 != null && cartItem.Variant.Option2 != undefined) {
        stringToReturn += `<p class="bw-isla-cart-text__size">Material: ${cartItem.Option3}</p>`
    }

    stringToReturn += `<input type="number" value="${cartItem.Quantity}" class="bw-isla-cart-text__input bw-cart-item-quantity" data-identity="cart-item-qty" data-qty="${cartItem.Quantity}">`;

    stringToReturn += `</div>
                            <div class="bw-isla-cart-text2">
                                <span class="bw-isla-cart-text2__delete" id="bw-isla-delete">&#10005;</span>
                                <p class="bw-isla-cart-text2__price"><br><span class="bw-cart-item-price" data-identity="cart-item-price" data-price="${cartItem.Price}">Rp ${cartItem.PriceMask}</span></p>
                            </div>
                        </div>
                    </div>`;

    return stringToReturn;
};